import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';
import { NavController, MenuController, ToastController, ModalController } from '@ionic/angular';

import { environment } from 'src/environments/environment';

import axios from 'axios';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Login } from '../../../interfaces/smart-jobsite/gate/login.interface';
import { User } from '../../../interfaces/smart-jobsite/gate/user.interface';

@Injectable({
    providedIn: 'root',
})
export class SmartJobsiteApiService {
    private axiosClient: AxiosInstance;

    public domain: string = 'smartjobsite.com/';
    public devGateDomain: string = 'https://dev.auth.' + this.domain;
    public prodGateDomain: string = 'https://auth.' + this.domain;
    public sharedDomain: string = '';
    public productionEnvironment: boolean = environment.production;

    public login: Login | undefined;
    public user: User | undefined;

    constructor(
        public storage: Storage,
        public navCtrl: NavController,
        public modalController: ModalController,
        private menuCtrl: MenuController,
        private toastCtrl: ToastController
    ) {
        this.storage.create();
        this.axiosClient = axios.create({
            timeout: 1000 * 60,
        });
    }

    public getGateUrl(): string {
        if (this.productionEnvironment) {
            return this.prodGateDomain;
        } else {
            return this.devGateDomain;
        }
    }

    public getSharedUrl(): string {
        return 'https://' + this.sharedDomain + '.' + this.domain;
    }

    /** API REQUESTS **/
    private getApiHeader(): AxiosRequestConfig {
        let axiosConfig: AxiosRequestConfig = {
            headers: {
                Authorization: 'Bearer ' + this.login?.accessToken,
                'Content-Type': 'application/json',
            },
        };
        return axiosConfig;
    }

    public async getGateServer(url: string): Promise<any> {
        try {
            const axiosResponse = await this.axiosClient.get(`${this.getGateUrl()}${url}`, this.getApiHeader());

            return axiosResponse.data;
        } catch (error: any) {
            if (error.response) {
                if (error.response.status == 401) {
                    if (await this.refreshToken()) {
                        return this.getGateServer(url);
                    }
                } else {
                    this.requestError(error.message);
                    return [];
                }
            }
        }
    }

    public async postGateServer(url: string, args: any): Promise<any> {
        let body = new FormData();

        for (var k in args) {
            body.append(k, args[k]);
        }

        try {
            const axiosResponse = await this.axiosClient.post(`${this.getGateUrl()}${url}`, body, this.getApiHeader());

            return axiosResponse.data;
        } catch (error: any) {
            if (error.response) {
                if (error.response.status == 401) {
                    if (await this.refreshToken()) {
                        return this.postGateServer(url, args);
                    }
                } else {
                    this.requestError(error.message);
                    return [];
                }
            }
        }
    }

    public async refreshToken(): Promise<boolean> {
        if (this.login != undefined) {
            let body = new FormData();
            body.append('refresh_token', this.login.refreshToken);

            try {
                const response = await this.axiosClient.post(
                    `${this.getGateUrl()}oauth/access-token`,
                    body,
                    await this.getApiHeader()
                );

                if (this.login != undefined && response.data != '') {
                    this.login.accessToken = response.data.access_token;
                    this.login.refreshToken = response.data.refresh_token;
                    this.login.expires = response.data.expires_in;

                    this.saveLogin();
                    return true;
                }
            } catch {}
        }
        this.logOut();
        return false;
    }

    public async authenticate(email: string, password: string) {
        this.login = {
            email: '',
            accessToken: '',
            refreshToken: '',
            expires: '',
        };

        let body = new FormData();
        body.append('email', email);
        body.append('password', password);
        body.append('license_type_id', '3');

        try {
            const loginResponse = await this.axiosClient.post(`${this.getGateUrl()}oauth/auth-token`, body);

            this.login.email = email;
            this.login.accessToken = loginResponse.data.access_token;
            this.login.refreshToken = loginResponse.data.refresh_token;
            this.login.expires = loginResponse.data.expires_in;

            const userReponse = await this.axiosClient.get(`${this.getGateUrl()}User`, this.getApiHeader());
            this.user = userReponse.data;

            this.saveLogin();
            this.menuCtrl.enable(true);
            this.navCtrl.navigateForward('/home');

            return true;
        } catch (error: any) {
            if (error.response) {
                this.requestError(error.response.data);
            }
            return false;
        }
    }

    public async getSharedServer(url: string): Promise<any> {
        try {
            const axiosResponse = await this.axiosClient.get(`${this.getSharedUrl()}${url}`, this.getApiHeader());

            return axiosResponse.data;
        } catch (error: any) {
            if (error.response) {
                if (error.response.status == 401) {
                    if (await this.refreshToken()) {
                        return this.getSharedServer(url);
                    }
                } else {
                    this.requestError(error.message);
                    return [];
                }
            }
        }
    }

    public async postSharedServer(url: string, args: any): Promise<any> {
        let body = new FormData();

        for (var k in args) {
            body.append(k, args[k]);
        }

        try {
            const axiosResponse = await this.axiosClient.post(
                `${this.getSharedUrl()}${url}`,
                body,
                this.getApiHeader()
            );

            return axiosResponse.data;
        } catch (error: any) {
            if (error.response) {
                if (error.response.status == 401) {
                    if (await this.refreshToken()) {
                        return this.postSharedServer(url, args);
                    }
                } else {
                    this.requestError(error.message);
                    return [];
                }
            }
        }
    }

    /** UTILS **/
    public async checkStorage() {
        this.login = await this.storage.get('login');
        this.user = await this.storage.get('user');

        if (this.login != null) {
            const response = await this.getGateServer('User');
            this.user = response;

            if (this.user?.IsAdmin == 1) {
                this.saveLogin();
                this.menuCtrl.enable(true);
                this.navCtrl.navigateForward('/home');
            } else {
                this.requestError('Not admin');
                this.navCtrl.navigateForward('/login');
            }
        } else {
            this.logOut();
        }
    }

    public saveLogin() {
        this.storage.remove('login');
        this.storage.remove('user');
        this.storage.set('login', this.login);
        this.storage.set('user', this.user);
    }

    public clearLogin() {
        this.login = undefined;
        this.user = undefined;
        this.storage.remove('login');
        this.storage.remove('user');
    }

    public logOut() {
        this.clearLogin();
        this.navCtrl.navigateRoot('/login');
    }

    public async requestError(error: any) {
        error = this.parseError(error);
        const toast = await this.toastCtrl.create({
            message: error,
            duration: 2000,
        });
        toast.present();
    }

    private parseError(error: string) {
        let errorString = '';
        if (error == 'BAD_CREDENTIALS') {
            errorString = 'The email or password you entered is incorrect.';
        } else if (error == 'LICENSE_NOT_VALID') {
            errorString = "Your user's license is invalid.";
        } else {
            errorString = error;
        }

        return errorString;
    }
}
