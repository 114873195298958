<ion-app>
  <ion-split-pane contentId="main-content" when="false">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>Smart Jobsite Loadcharts</ion-list-header>
          <ion-note *ngIf="!apiService.productionEnvironment">
            <ion-icon name="alert-circle" color="danger"></ion-icon><ion-label color="danger"> Development</ion-label>
          </ion-note>
          <ion-note *ngIf="apiService.user" color="primary" class="note-link">
            {{ apiService.user.First_Name }}
            {{ apiService.user.Last_Name }}
          </ion-note>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list id="labels-list">
          <ion-item *ngFor="let other of others" routerDirection="root" [routerLink]="[other.url]" lines="none">
            <ion-icon slot="start" [ios]="other.icon + '-outline'" [md]="other.icon + '-sharp'"></ion-icon>
            <ion-label>{{ other.title }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
      <ion-footer>
        <img *ngIf="prefersDark.matches" class="img-footer" src="./../assets/logo/Smart Jobsite.Logo.Dark.Tr.png" />
        <img *ngIf="!prefersDark.matches" class="img-footer" src="./../assets/logo/Smart Jobsite.Logo.Clear.Tr.png" /> </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
