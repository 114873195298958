import { Component } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Platform } from '@ionic/angular';

import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs/operators';

import { SmartJobsiteApiService } from './services/api/smart-jobsite/smart-jobsite-api.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    private appVersion: string = environment.CURRENT_VERSION;

    public prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    public appPages = [{ title: 'Loadchart', url: '/home', icon: 'apps' }];

    public others = [
        {
            title: 'Logout',
            url: '/loading',
            icon: 'log-out',
        },
    ];

    constructor(private platform: Platform, private swUpdate: SwUpdate, public apiService: SmartJobsiteApiService) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.apiService.checkStorage();
            this.checkForUpdates();
        });
    }

    async checkForUpdates() {
        const updatesAvailable = this.swUpdate.versionUpdates.pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            map((evt) => ({
                type: 'UPDATE_AVAILABLE',
                current: evt.currentVersion,
                available: evt.latestVersion,
            })) 
        );
        updatesAvailable.subscribe(async (data) => {
            if (data.type == 'UPDATE_AVAILABLE') {
                this.swUpdate.activateUpdate().then(() => window.location.reload());
            }
        });
        this.swUpdate.checkForUpdate();
        setInterval(() => {
            this.swUpdate.checkForUpdate();
        }, 15 * 60 * 1000);
    }

    getAppVersion(): string {
        if (environment.production) {
            return this.appVersion;
        } else {
            return this.appVersion + '-dev';
        }
    }
}
